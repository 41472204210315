#chatLog {
  height: 500px;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  overflow-y: scroll;
}
.message {
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  white-space: pre-line;
}
.user {
  background-color: #e6f7ff;
  text-align: right;
}
.chatbot {
  background-color: #d9d9d9;
}
/* Loading Animation */
.loader {
  display: inline-block;
  width: 50%;
  height: 10px;
  border-radius: 10px;
  background: linear-gradient(-90deg, #f0f0f0 50%, #d9d9d9 50%);
  background-size: 200% 100%;
  animation: loading 3s infinite;
}
@keyframes loading {
  0% { background-position: 200% 0; }
  100% { background-position: -200% 0; }
}
.hidden {
  display: none;
}
